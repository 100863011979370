.outlined-textarea {
  width: 100%;
}

.outlined-textarea .ql-container {
  border: none;
}

.outlined-textarea,
.outlined-textarea .ql-container,
.outlined-textarea .ql-editor {
  min-height: 200px;
}

.input-toolbar .ql-font {
  width: 125px !important;
}

.input-toolbar .ql-size {
  width: 125px !important;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}
.ql-size-huge,
.ql-size span[data-value="huge"]::before {
  font-size: 22px !important;
}
